@import "../App.scss";

.page {
  position: absolute;
  top: 0;
  left: 17vw;
  padding: 5vh 5vw;
  @include flex(column, flex-start, flex-start);
  gap: 0.5rem;
  width: 83vw;

  form {
    width: 100%;

    .form-group {
      width: 100%;

      .buttons {
        width: 100%;
        @include flex(row, flex-start, flex-start);
        gap: 1rem;
        margin-top: 1rem;

        .btn {
          width: 100%;
          padding: 0.5rem 1rem;
          border: none;
          outline: none;
          cursor: pointer;
          background-color: $gray-6;
          color: $white;
          transition: all 0.3s ease-in-out;
          font-size: 1rem;
          text-transform: uppercase;
          font-weight: 300;
          font-size: 0.8rem;

          &:hover {
            background-color: $gray-5;
          }
        }
      }

      .images {
        @include flex(row, flex-start, flex-start);
        gap: 1rem;
        width: 100%;
      }
    }
  }

  .table {
    width: 100%;
    border-collapse: collapse;
    font-size: 0.9rem;

    thead {
      background-color: $white;

      th {
        padding: 1rem;
        text-align: left;
        border: 1px solid $gray-2;
      }
    }

    tbody {
      tr {
        background-color: $white;

        td {
          border: 1px solid $gray-2;
          padding: 0.5rem 1rem;
          text-align: left;
          font-size: 0.8rem;

          a,
          button {
            border: none;
            outline: none;
            cursor: pointer;
            background-color: $gray-5;
            color: $white;
            transition: all 0.3s ease-in-out;
            padding: 0.5rem 1rem;
            font-size: 0.8rem;
            margin-right: 1rem;
            max-width: 5rem;
          }
        }
      }
    }
  }
}
