$white: #fff;
$black: #000;
$gray-1: #f5f5f5;
$gray-2: #e4e4e4;
$gray-3: #cfcfcf;
$gray-4: #949494;
$gray-5: #343434;
$gray-6: #242424;
$gray-7: #161616;

$primary: #f90030;
$primary-light: $gray-5;

@font-face {
  font-family: "OpenSans";
  src: url("../src/assets/fonts/OpenSans.ttf") format("truetype");
}

@font-face {
  font-family: "RobotoFlex";
  src: url("../src/assets/fonts/RobotoFlex.ttf") format("truetype");
}

* {
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font: 0.9rem "OpenSans", sans-serif;
  color: $black;
  width: 100%;
  overflow-x: hidden;
  position: relative;
  background-color: #fbfbfb;
}

h1 {
  font-size: 1.3rem;
  font-weight: 500;
  color: $black;
  font-family: "RobotoFlex", sans-serif;
}

h2 {
  font-size: 1.5rem;
  font-weight: 500;
  color: $black;
  font-family: "RobotoFlex", sans-serif;
}

h3 {
  font-size: 2.2rem;
  font-weight: 500;
  color: $black;
  font-family: "RobotoFlex", sans-serif;
}

h4 {
  font-size: 2.2rem;
  font-weight: 500;
  color: $black;
  font-family: "RobotoFlex", sans-serif;
}

h5 {
  font-size: 4rem;
  font-weight: 500;
  color: $black;
  font-family: "RobotoFlex", sans-serif;
}

p {
  font-size: 0.8rem;
  font-weight: 500;
  color: $black;
}

ul {
  list-style: none;
}

img {
  width: 100%;
  height: auto;
}

a {
  font-size: 0.9rem;
}

@mixin flex($direction, $justify, $align) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
}

.form,
form {
  @include flex(column, flex-start, flex-start);
  width: 100%;

  .form-group {
    width: 100%;
    @include flex(column, flex-start, flex-start);
    min-width: 20rem;
    margin-bottom: 1rem;

    label {
      font-size: 0.7rem;
      font-weight: 400;
      color: $black;
      margin-bottom: 0.5rem;
    }

    .quill {
      width: 100%;
      background-color: $white;
      margin-bottom: 1rem;
    }

    input,
    textarea {
      border: none;
      outline: none;
      font-size: 0.8rem;
      font-weight: 400;
      color: $black;
      padding: 0.7rem 1rem;
      background-color: $white;
      width: 100%;
      transition: all 0.3s ease;
      border: 1px solid $gray-3;

      &:focus {
        border: 1px solid $black;
      }
    }

    section {
      width: 100%;
      height: 10vh;
      @include flex(row, center, center);
      border: 1px solid $gray-3;
      background-color: $white;

      div {
        width: 100%;
        height: 100%;
        @include flex(row, center, center);
      }
    }

    .images {
      margin: 1rem 0;
      width: 100%;
      @include flex(row, flex-start, flex-start);
      flex-wrap: wrap;
      gap: 1rem;

      .image {
        position: relative;
      }

      button {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
        border: none;
        outline: none;
        cursor: pointer;
        padding: 0.5rem;
        border-radius: 0;
        transition: all 0.3s ease;
        color: $black;
        z-index: 1;
        width: auto;
        @include flex(row, center, center);
        color: $black;
        background-color: rgba($color: $white, $alpha: 0.5);
        font-size: 1.2rem;

        &:hover {
          scale: 1.1;
        }
      }

      img {
        height: 20rem;
      }

      video {
        height: 20rem;
      }
    }

    .error {
      color: red;
      font-size: 0.8rem;
    }
  }

  button {
    border: none;
    outline: none;
    font-weight: 400;
    color: $white;
    padding: 0.7rem 1rem;
    background-color: $gray-6;
    text-transform: none;
    transition: all 0.3s ease;
    cursor: pointer;
    margin-bottom: 0.5rem;
  }
}

select {
  border: none;
  outline: none;
  font-size: 0.9rem;
  font-weight: 400;
  color: $gray-5;
  padding: 0.3rem 6rem;
  background-color: $white;
  transition: all 0.3s ease;
  border-radius: 0.5rem;
  border: 1px solid $gray-3;

  &:focus {
    border: 1px solid $primary;
    color: $black;
  }

  option {
    font-size: 0.8rem;
    font-weight: 400;
    color: $black;
    padding: 0.7rem 1rem;
    background-color: $white;
    width: 100%;
    transition: all 0.3s ease;
    border: 1px solid $gray-3;
  }
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.button {
  position: relative;
  margin: 0;
  padding: 0.8em 1em;
  outline: none;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  text-transform: uppercase;
  background-color: #333;
  color: #fff;
  font-size: 0.8rem;
  font-family: inherit;
  z-index: 0;
  overflow: hidden;
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1);
}

.button:hover {
  animation: sh0 0.5s ease-in-out both;
}

@keyframes sh0 {
  0% {
    transform: rotate(0deg) translate3d(0, 0, 0);
  }

  25% {
    transform: rotate(7deg) translate3d(0, 0, 0);
  }

  50% {
    transform: rotate(-7deg) translate3d(0, 0, 0);
  }

  75% {
    transform: rotate(1deg) translate3d(0, 0, 0);
  }

  100% {
    transform: rotate(0deg) translate3d(0, 0, 0);
  }
}

.button:hover span {
  animation: storm 0.7s ease-in-out both;
  animation-delay: 0.06s;
}

.button::before,
.button::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: #fff;
  opacity: 0;
  transition: transform 0.15s cubic-bezier(0.02, 0.01, 0.47, 1),
    opacity 0.15s cubic-bezier(0.02, 0.01, 0.47, 1);
  z-index: -1;
  transform: translate(100%, -25%) translate3d(0, 0, 0);
}

.button:hover::before,
.button:hover::after {
  opacity: 0.15;
  transition: transform 0.2s cubic-bezier(0.02, 0.01, 0.47, 1),
    opacity 0.2s cubic-bezier(0.02, 0.01, 0.47, 1);
}

.button:hover::before {
  transform: translate3d(50%, 0, 0) scale(0.9);
}

.button:hover::after {
  transform: translate(50%, 0) scale(1.1);
}
