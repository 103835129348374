@import "../App.scss";

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 15vw;
  height: 100vh;
  @include flex(column, center, center);

  .sidebar-container {
    @include flex(column, flex-start, center);
    background-color: $white;
    width: 100%;
    height: 100%;
    position: relative;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

    .sidebar-container__logo {
      @include flex(column, center, center);
      width: 100%;
      padding: 2rem 2rem;

      img {
        width: 100%;
        height: auto;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
      }
    }

    .sidebar-container-menu {
      @include flex(column, flex-start, center);
      width: 100%;

      //edit for me a scroll bar
      &::-webkit-scrollbar {
        width: 0.5rem;
      }

      &::-webkit-scrollbar-track {
        background-color: rgba($color: $black, $alpha: 0.2);
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 0.5rem;
        background-color: rgba($color: $black, $alpha: 0.3);
      }

      .sidebar-container-menu-item {
        @include flex(column, flex-start, flex-start);
        width: 100%;
        transition: all 0.3s ease-in-out;

        .sidebar-container-menu-item-link {
          @include flex(row, flex-start, center);
          width: 100%;
          padding: 0.7rem 1rem;
          gap: 0.5rem;
          text-decoration: none;
          border: none;
          outline: none;
          cursor: pointer;
          transition: all 0.3s ease-in-out;
          position: relative;
          background-color: $white;
          border-bottom: 1px solid $gray-2;

          &.active {
            background-color: rgba($color: $gray-3, $alpha: 0.2);
          }

          .sidebar-container-menu-item-link-icon {
            font-size: 1.2rem;
            color: $black;
          }

          .sidebar-container-menu-item-link-text {
            font-size: 0.8rem;
            font-weight: 500;
            color: $black;
          }

          .sidebar-container-menu-item-link-arrow {
            position: absolute;
            right: 1rem;
            font-size: 0.8rem;
            color: $black;
            transform: rotate(0deg);
            width: 1rem;
            height: 1rem;
            transition: all 0.3s ease-in-out;

            &.active {
              transform: rotate(-180deg);
            }
          }
        }

        .sidebar-container-menu-item-submenu {
          @include flex(column, flex-start, center);
          width: 100%;
          max-height: 0;
          opacity: 0;
          visibility: hidden;
          transition: all 0.3s ease-in-out;

          &.active {
            max-height: 10vh;
            opacity: 1;
            visibility: visible;
            padding: 0.5rem 0;
          }

          .sidebar-container-menu-item-link {
            padding: 0.5rem 1rem;
            width: calc(100% - 2rem);
            margin-left: 2rem;
            box-shadow: none;
          }
        }
      }
    }

    .sidebar-container-footer {
      position: absolute;
      bottom: 1rem;
      left: 0;
      width: 100%;
      padding: 0.5rem 0;
      @include flex(column, center, center);

      p {
        font-size: 0.7rem;
        color: $gray-5;
      }

      .logut {
        @include flex(row, center, center);
        padding: 0.7rem 1rem;
        gap: 0.5rem;
        text-decoration: none;
        border: none;
        outline: none;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        position: relative;
        background-color: $gray-6;
        margin: 1.5rem 3rem;

        .logout-icon {
          font-size: 1.2rem;
          color: $white;
        }

        .logout-text {
          font-size: 0.8rem;
          font-weight: 400;
          color: $white;
        }
      }
    }
  }
}

.logut {
  position: relative;
  margin: 0;
  padding: 0.8em 1em;
  outline: none;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  text-transform: uppercase;
  background-color: #333;
  color: #fff;
  font-weight: 300;
  font-size: 0.8rem;
  font-family: inherit;
  z-index: 0;
  overflow: hidden;
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1);
}

.logut:hover {
  animation: sh0 0.5s ease-in-out both;
}

@keyframes sh0 {
  0% {
    transform: rotate(0deg) translate3d(0, 0, 0);
  }

  25% {
    transform: rotate(7deg) translate3d(0, 0, 0);
  }

  50% {
    transform: rotate(-7deg) translate3d(0, 0, 0);
  }

  75% {
    transform: rotate(1deg) translate3d(0, 0, 0);
  }

  100% {
    transform: rotate(0deg) translate3d(0, 0, 0);
  }
}

.logut:hover span {
  animation: storm 0.7s ease-in-out both;
  animation-delay: 0.06s;
}

.logut::before,
.logout::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: #fff;
  opacity: 0;
  transition: transform 0.15s cubic-bezier(0.02, 0.01, 0.47, 1),
    opacity 0.15s cubic-bezier(0.02, 0.01, 0.47, 1);
  z-index: -1;
  transform: translate(100%, -25%) translate3d(0, 0, 0);
}

.logut:hover::before,
.logut:hover::after {
  opacity: 0.15;
  transition: transform 0.2s cubic-bezier(0.02, 0.01, 0.47, 1),
    opacity 0.2s cubic-bezier(0.02, 0.01, 0.47, 1);
}

.logut:hover::before {
  transform: translate3d(50%, 0, 0) scale(0.9);
}

.logut:hover::after {
  transform: translate(50%, 0) scale(1.1);
}
