@import "../App.scss";

.page-login {
  width: 100%;
  height: 100vh;
  @include flex(column, center, center);
  position: relative;
  background-color: $white;

  .logo {
    width: 15rem;
    height: auto;
    margin-bottom: 1rem;
    position: absolute;
    top: 2rem;
    left: 50%;
    transform: translateX(-50%);
  }

  .container {
    padding: 2rem;
    background-color: $white;
    border: 1px solid $gray-2;

    h3 {
      margin-bottom: 1rem;
      font-size: 1.7rem;
      text-transform: uppercase;
      width: 100%;
      text-align: center;
    }

    p {
      font-size: 0.8rem;
      margin-bottom: 1rem;
      color: $gray-5;
      width: 100%;
    }
  }

  form {
    @include flex(column, center, center);

    button {
      padding: 0.6rem 1rem;
      width: 100%;
    }
  }
}
